import * as React from "react";
import Header from "../components/header";
import "@fontsource/roboto/900-italic.css";
import "@fontsource/roboto/400.css";
import "../styles/content.scss";
import "../styles/register.scss";
import Footer from "../components/footer";
import bullet from "../images/tick.png";
import Seo from "../components/helmet";

const bullets = ["Choice ", "Transparency", "Predictability", "Flexibility"];

const RegisterPage = () => {
  return (
    <React.Fragment>
      <Seo title="Register Seller - Flock Finance"></Seo>
      <Header></Header>

      <title>Register</title>

      <h1 className="title-banner">REGISTER</h1>

      <div className="button-toggle">
        <div className="button-dark">SELLER&nbsp;&nbsp;&nbsp;</div>

        <a className="button-clear no-left" href="/register">
          BUYER
        </a>
      </div>

      <div className="title-message">
        Thank you for choosing to register with Flock - welcome to the
        community!
        <br />
        <br />
        Please select either Buyer or Seller and fill in the details below. One
        of our friendly staff will contact you to finalise the registration
        process.
      </div>

      <div className="register-go">
        <div className="explainer">
          <div className="exp-title">SELLERS</div>
          <div className="exp-content">
            Are you a business looking to raise finance and better manage your
            cash flow?
            <br />
            <br />
            Flock is levelling the playing field. We’re starting by making the
            buying and selling of invoices an open and accessible marketplace
            for individual investors and businesses of all sizes. This means
            more options, better rates and more predictable returns.
          </div>
          <div className="exp-bullets">
            {bullets.map((b, i) => (
              <div className="exp-bullet" key={i}>
                <img src={bullet} />
                <p>{b}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="form">
          <iframe
            src="https://tally.so/embed/wvGBjA?hideTitle=1&transparentBackground=1"
            width="100%"
            height="600"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            title="Register Sellers"
          ></iframe>
        </div>
      </div>

      <Footer></Footer>
    </React.Fragment>
  );
};

export default RegisterPage;
