import React from "react";
import "../styles/footer.scss";
import logo from "../images/Flock_logo_white.png";

export default function Footer() {
  return (
    <React.Fragment>
      <div className="footer">
        <div className="contact">
          <div className="text">CONTACT</div>
          <a className="email" href="mailto:info@flockfinance.com.au">
            info@flockfinance.com.au
          </a>
        </div>
        <div className="links">
          <div className="copyright">© 2022 Flock. All rights reserved</div>
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms and Conditions</a>
        </div>
        <div
          className="white-logo"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <img alt="Flock Logo" src={logo} />
        </div>
      </div>
      <div className="disclaimer">
        <div className="title">Disclaimer</div>
        <div className="content">
          This site is directed and available to and for the benefit of
          Australian residents only. © Commonwealth Securities Limited ABN 60
          067 254 399 AFSL 238814 ("CommSec") is a wholly owned, but non
          guaranteed, subsidiary of the Commonwealth Bank of Australia ABN 48
          123 123 124 AFSL 234945 and both entities are incorporated in
          Australia with limited liability.
        </div>
      </div>
    </React.Fragment>
  );
}
