import React from "react";
import "../styles/header.scss";
import logo from "../images/Flock_logo_blue.png";
const { useState } = React;
const data = [
  {
    label: "ABOUT",
    path: "/",
  },
  {
    label: "CONTACT",
    path: "mailto:info@flockfinance.com.au",
  },
  {
    label: "FAQ",
    path: "/faq",
  },
];

export default function Header() {
  const [visible, setVisible] = useState(false);
  return (
    <React.Fragment>
      <div className={visible ? "modal-menu" : "hide-menu"}>
        <div
          className="close-container"
          onClick={() => {
            setVisible(false);
            document.body.style.overflow = "unset";
          }}
        >
          <div className="close">+</div>
        </div>
        <div
          className="logo"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <img alt="Flock Logo" src={logo} />
        </div>
        <div className="links">
          {data.map(({ path, label }, i) => (
            <a href={path} key={i}>
              {label}
            </a>
          ))}
          <a href="https://app.flockfinance.com.au" key="login">
            LOG IN
          </a>
          <a href="/register" key="register">
            REGISTER
          </a>
        </div>
      </div>
      <div className="header-root">
        <div className="banner">BETA</div>
        <div
          className="logo"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <img alt="Flock Logo" src={logo} />
        </div>
        <div className="header-container">
          <button
            className="hamburger"
            aria-label="Main Menu"
            onClick={() => {
              setVisible(true);
              document.body.style.overflow = "hidden";
            }}
          >
            <div></div>
            <div></div>
            <div></div>
          </button>
          <div className="header">
            {data.map(({ path, label }, i) => (
              <a href={path} key={i}>
                {label}
              </a>
            ))}
            <div className="spacer"></div>
            <a href="https://app.flockfinance.com.au" key="login">
              LOG IN
            </a>
            <div className="skewed-btn">
              <a href="/register" key="register">
                REGISTER
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
